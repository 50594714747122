import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cards from "../components/cards/cards"
import Video from "../components/video/video"
import bgVideo from '../components/video/02_THS_Animatic_Final_TE-MC-4K-Compressed.mp4'
import videoPoster from "../components/video/video-poster-te-4k.jpg"

const TEMediaCafe = () => (
  <Layout>
    <SEO title="Media Cafe" /> 
    <div className="cardsContainer cardsContainer--te-media-cafe">
        <Cards postCount={5} hero={true} />
        <Video src={bgVideo} poster={videoPoster} />
    </div>
  </Layout>
)

export default TEMediaCafe
